import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/en';

const Services = (props) => (
  <Layout bodyClass="page-services" location={props.location}>
    <SEO title="Our Services"/>
    <div className="intro heading">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-2">Our Services</h1>
            <p className="text-center">At Massam Corporate Consulting, we’re dedicated to helping our clients, from
              start-ups to multinational
              companies – and the work we do together with them is as varied as they are.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="container p-2 p-md-5 services justify-content-center">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center mb-4 text-dark">
            Our Service Lines
          </h3>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble advisory">
            <h4>Business Advisory</h4>
          </div>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble tax">
            <h4>Taxation</h4>
          </div>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble strategy">
            <h4>Operation Strategy</h4>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className="goals mb-4 mb-md-8">
        <p className="text-dark">
          We help organizations seize opportunities. We help them fulfil regulatory requirements, keep investors
          informed and meet stakeholder needs. In a fast-changing world, we give them the support they need to be
          effective today and create long-term growth for tomorrow.
        </p>
        <div className="goals-hero growth"/>
      </div>

      <div className="goals mb-4 mb-md-8">
        <p className="text-dark flip">
          Across all disciplines and from every angle, Massam Corporate Consulting draws on our shared creativity,
          experience, judgment and diverse perspectives. Wherever they operate, we help clients address their
          challenges, so their businesses are fit for a digital future.
        </p>
        <div className="goals-hero digitalisation flip"/>
      </div>
    </div>
  </Layout>
);

export default Services;
